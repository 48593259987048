import {
  formatTitle,
  LabelledCell,
  LabelledCellDef,
  MaskFullStory,
  PHI,
  ProductName,
  ProductPreviewContext,
  SyncProgress,
  TwoToneHeader,
  useHasPermission,
} from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonOffIcon from '@mui/icons-material/PersonOffOutlined';
import {
  alpha,
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { DialogToolbar, VaultAutoLogin } from '..';
import { NAVBAR_CONFIG } from '../../../config';
import { useClaimCredentials, useStatusMapping } from '../../../hooks';
import {
  ClaimDetails,
  ClaimResponse,
  RedactedCredential,
  StatusMapping,
} from '../../../types';
import {
  formatTime,
  isClearinghouseResponse,
  isInsideDialResponse,
} from '../../../utils';
import LinkButton from '../../LinkButton';

type Extends<T, U extends T> = U;

type CredentialIssueStatus = Extends<
  ClaimResponse['status_code']['client_status_code'],
  8 | 10 | 15 | 16
>;

const CREDENTIAL_ISSUE_STATUSES: readonly CredentialIssueStatus[] = [
  8, 10, 15, 16,
];

const CREDENTIAL_ISSUE_DESCRIPTIONS: Record<
  CredentialIssueStatus,
  {
    description: string;
    displayVaultButton: boolean;
    linkText?: string;
  }
> = {
  8: {
    description: 'Facility Issue - Unable to find the Facility and/or Tax ID.',
    linkText:
      'Please click here to update the username and/or password for the correct facility and/or Tax ID',
    displayVaultButton: true,
  },
  10: {
    description:
      'Incorrect username/password - Please update the username and/or password for the payor portal.',
    linkText:
      'Please click here to update the username and/or password for the payor portal.',
    displayVaultButton: false,
  },
  15: {
    description:
      'Portal Action Required - Please visit the payor portal to verify your configurations.',
    displayVaultButton: true,
  },
  16: {
    description:
      'Update Multi-Factor Authentication - The Multi-Factor Authentication for this portal has expired.',
    linkText:
      'Please click here to re-enter the username and password for this portal to update the Multi-Factor Authentication.',
    displayVaultButton: false,
  },
};

function isCredentialIssue(
  code: ClaimResponse['status_code']['client_status_code'],
): code is CredentialIssueStatus {
  return CREDENTIAL_ISSUE_STATUSES.includes(code);
}

function CredentialIssueResponseItem(props: {
  checkedDate: string | null;
  claimId: number;
  clientCode: CredentialIssueStatus;
  credential: RedactedCredential | null;
  handleNotAuthorized: () => void;
  portalURL: string | null;
}) {
  const {
    checkedDate,
    claimId,
    clientCode,
    credential,
    handleNotAuthorized,
    portalURL,
  } = props;
  const credentialDescription = CREDENTIAL_ISSUE_DESCRIPTIONS[clientCode];
  const { hasPermission } = useHasPermission();
  const cannotWriteCredentials = !hasPermission('write:credentials');
  return (
    <Card
      variant='outlined'
      sx={{
        borderColor: 'error.dark',
        color: 'error.dark',
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 2,
        }}
      >
        <Grid
          direction='column'
          sx={{
            fontSize: '0.875rem',
            fontWeight: variables.weightMedium,
            marginRight: 'auto',
          }}
        >
          <Grid item>{credentialDescription.description}</Grid>
          {credential !== null && credentialDescription?.linkText && (
            <Grid item>
              <Link
                href={`${NAVBAR_CONFIG.credentialsUrl}/${credential.id}`}
                target='_blank'
                rel='norefferer noopener'
                color='error.dark'
                sx={{ fontWeight: variables.weightSemiBold }}
                onClick={(e) => {
                  if (cannotWriteCredentials) {
                    e.preventDefault();
                    handleNotAuthorized();
                  }
                }}
              >
                Please click here to re-enter the username and password for the
                portal to update the credential
              </Link>
            </Grid>
          )}
        </Grid>
        {credentialDescription.displayVaultButton && (
          <Grid item>
            <VaultAutoLogin claimId={claimId} portal={portalURL} />
          </Grid>
        )}
        <Grid item>
          <LabelledCell
            label='Checked'
            value={formatTime(checkedDate, 'date') ?? '-'}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

function RegularResponseItem({
  response,
  statusMapping,
}: {
  response: ClaimResponse;
  statusMapping: StatusMapping;
}) {
  const { hasPermission } = useHasPermission();
  const clientCode = response.status_code.client_status_code;
  const clientStatus = statusMapping[clientCode]?.name ?? 'Unknown';
  const serverStatus = response.status_code.server_status_code;
  const { credential } = response;

  const cells: LabelledCellDef[] = [
    {
      label: 'Status',
      value: formatTitle(
        hasPermission('read:claim-response-status-code')
          ? `${clientStatus} ${serverStatus}`
          : clientStatus,
      ),
    },
    { label: 'Check Date', value: formatTime(response.checked, 'date') },
    {
      label: 'Credential Status',
      value: formatTitle(credential?._status ?? '-'),
    },
    {
      label: 'Username',
      value: (
        <MaskFullStory>
          <PHI>{credential?.username ?? '-'}</PHI>
        </MaskFullStory>
      ),
    },
  ];

  return (
    <Card variant='outlined'>
      <Grid container padding={2} spacing={2}>
        {cells.map((cell) => (
          <Grid
            key={typeof cell.label === 'string' ? cell.label : cell.description}
            item
            xs
            display='flex'
            alignItems='center'
          >
            <LabelledCell {...cell} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
}

function ResponseHistoryDialog({
  open,
  onClose,
  claimDetails,
  statusMapping,
}: {
  open: boolean;
  onClose: () => void;
  claimDetails: ClaimDetails;
  statusMapping: StatusMapping;
}) {
  const { responses } = claimDetails;

  const [displayNotAuthorized, setDisplayNotAuthorized] = useState(false);

  const handleNotAuthorized = useCallback(() => {
    setDisplayNotAuthorized(true);
    onClose();
  }, [onClose]);

  if (displayNotAuthorized) {
    return (
      <NotAuthorizedModal
        open={displayNotAuthorized}
        onClose={() => setDisplayNotAuthorized(false)}
      />
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogToolbar
        onClose={onClose}
        fontSize='22px'
        fontWeight={variables.weightRegular}
        text={['Claim ', 'Response History']}
        icon={<AnnouncementOutlinedIcon />}
        flexGrow={1}
      />
      <DialogContent>
        <Stack padding={2} spacing={3}>
          {responses.map((response) => {
            const clientCode = response.status_code.client_status_code;
            const credentialIssue = isCredentialIssue(clientCode);
            if (credentialIssue) {
              return (
                <CredentialIssueResponseItem
                  checkedDate={response.checked}
                  claimId={claimDetails.id}
                  clientCode={clientCode}
                  credential={response.credential}
                  handleNotAuthorized={handleNotAuthorized}
                  key={response.id}
                  portalURL={claimDetails.mapped_collector?.portal_url ?? null}
                />
              );
            }
            return (
              <RegularResponseItem
                key={response.id}
                response={response}
                statusMapping={statusMapping}
              />
            );
          })}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default function ResponseHistoryCard({
  claimDetails,
}: {
  claimDetails: ClaimDetails;
}) {
  const statusMappingQuery = useStatusMapping();
  const credentialsQuery = useClaimCredentials(claimDetails.id);
  const [responseHistoryDialogOpen, setResponseHistoryDialogOpen] =
    useState(false);

  const { bestCode, latestCode } =
    getBestAndLatestClientStatusCode(claimDetails);
  const credentialIssue = isCredentialIssue(latestCode);
  const date = formatTime(claimDetails.best_response.checked, 'date');

  const status = statusMappingQuery.data?.[bestCode]?.name;

  const color = useGetResponseHistoryFontColor(claimDetails);
  let text = `${status ?? 'Unknown'} - ${date}`;
  let Icon = InfoOutlinedIcon;
  let showCardTitle = true;
  let linkUnderline = true;

  if (credentialIssue) {
    Icon = ErrorOutlineIcon;
    text = CREDENTIAL_ISSUE_DESCRIPTIONS[latestCode].description;
    showCardTitle = false;
  }

  if (
    isClearinghouseResponse(claimDetails.best_response) &&
    status === 'Validation Error'
  ) {
    text = 'Validation Errors - See Clearinghouse Notes';
    linkUnderline = false;
  }
  if (claimDetails.closed) {
    text = 'This claim is closed in the PMS';
    showCardTitle = true;
  }
  if (claimDetails.categories.includes('resolved')) {
    text = 'This claim is closed';
    showCardTitle = true;
  }
  if (claimDetails.deleted) {
    text = 'This claim is deleted';
    showCardTitle = true;
  }

  const { preview } = useContext(ProductPreviewContext);

  return (
    <Card
      variant='outlined'
      sx={{ borderColor: alpha(color, 0.15) }}
      data-testid='response-history-card'
    >
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
        sx={{ px: 2, py: 1 }}
      >
        <Icon sx={{ fontSize: '22px', color }} />
        <Typography
          component='div'
          fontSize='14px'
          fontWeight={variables.weightSemiBold}
          color={color}
        >
          {showCardTitle && (
            <div>{getResponseHistoryStatusHeader(claimDetails)}</div>
          )}
          {(statusMappingQuery.isLoading || credentialsQuery.isLoading) && (
            <Stack direction='row' spacing={1} alignItems='center' pl={0.5}>
              <SyncProgress />
              <span>Loading...</span>
            </Stack>
          )}
          {statusMappingQuery.isSuccess && credentialsQuery.isSuccess && (
            <LinkButton
              color='inherit'
              onClick={() => setResponseHistoryDialogOpen(true)}
              underline={linkUnderline}
              disabled={preview}
            >
              {text}
            </LinkButton>
          )}
          {(statusMappingQuery.isError || credentialsQuery.isError) && (
            <Box color='error.main'>Error loading claim response</Box>
          )}
        </Typography>
      </Stack>
      {statusMappingQuery.data !== undefined && (
        <ResponseHistoryDialog
          open={responseHistoryDialogOpen}
          onClose={() => setResponseHistoryDialogOpen(false)}
          claimDetails={claimDetails}
          statusMapping={statusMappingQuery.data}
        />
      )}
    </Card>
  );
}

function useGetResponseHistoryFontColor(claimDetails: ClaimDetails) {
  const { palette } = useTheme();
  const { latestCode } = getBestAndLatestClientStatusCode(claimDetails);

  let color = palette.primary.main;
  if (isClearinghouseResponse(claimDetails.best_response))
    color = palette.secondary.main;
  if (isInsideDialResponse(claimDetails.best_response))
    color = palette.success.main;
  if (isCredentialIssue(latestCode)) color = palette.error.dark;
  if (claimDetails.closed) color = palette.warning.main;
  if (claimDetails.categories.includes('resolved')) {
    color = palette.warning.main;
  }
  if (claimDetails.deleted) color = palette.error.main;

  return color;
}

function getBestAndLatestClientStatusCode(claimDetails: ClaimDetails) {
  const bestCode = claimDetails.best_response.status_code.client_status_code;
  const latestCode =
    claimDetails.responses[0]?.status_code?.client_status_code ?? bestCode;
  return { bestCode, latestCode };
}

function getResponseHistoryStatusHeader(claimDetails: ClaimDetails) {
  if (isClearinghouseResponse(claimDetails.best_response))
    return 'Clearinghouse Response:';
  if (isInsideDialResponse(claimDetails.best_response))
    return (
      <>
        <ProductName product='dial' />:
      </>
    );
  return 'Claim Response:';
}

function NotAuthorizedModal(props: { open: boolean; onClose: () => void }) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { paddingX: 3 } }}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 2,
        }}
      >
        <PersonOffIcon
          sx={{ color: 'primary.main', fontSize: '5rem', flexGrow: '1' }}
        />
        <IconButton onClick={onClose} sx={{ marginTop: -4 }} aria-label='close'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <TwoToneHeader
          text={['Not ', 'Authorized']}
          sx={{
            fontSize: '2.5rem',
            fontWeight: variables.weightSemiBold,
            paddingY: 2,
            textAlign: 'center',
          }}
        />
        <Typography color='primary' sx={{ paddingTop: 2 }}>
          OOPS, it looks like you do not have permissions to update and/or edit
          Payor Portal Credentials. If you believe this message is an error
          please contact your account administrator to update your permissions
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
