import {
  ClientAPIContext,
  ExcludeFullStory,
  formatTitle,
  KeyboardShortcutBarrier,
  ProductName,
  ProductPreviewContext,
  useFlags,
  useHasPermission,
  useSnackbar,
  useUserProfile,
} from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import { LinkOff } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  buttonClasses,
  ButtonProps,
  Card,
  CardContent,
  CardHeader,
  cardHeaderClasses,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { saveAs } from 'file-saver';
import { ReactNode, useContext, useId, useMemo, useState } from 'react';
import invariant from 'tiny-invariant';
import { Artifact, RoundedTabList, ServiceTable } from '.';
import { PREVIEW_CONFIG } from '../../config';
import {
  ClaimDetails,
  ClaimResponseEntry,
  ClaimResponseEntryArtifact,
} from '../../types';
import { formatUpgradeRequestSupportUrl } from '../../utils';
import { ExternalLink } from '../ExternalLink';
import UnmatchClaimDialog from './UnmatchClaimDialog/UnmatchClaimDialog';

const TAG_ORDER = [
  'eob',
  'era',
  'detail',
  'payment',
  'utilization',
  'claim',
  'listing',
  'error',
  'login',
  'internal',
  /* XXX: Reversal allows determining order with indexOf while properly ordering
   * non-specified tags last.
   */
].reverse();

const OVERVIEW_TAB = 'overview';

type ArtifactWithTag = ClaimResponseEntryArtifact & {
  tag: NonNullable<ClaimResponseEntryArtifact['tag']>;
};

type ArtifactTypeRequiresHeader = Extract<
  ClaimDetails['best_response']['type' | 'collector_type'],
  'inside_dial' | 'clearinghouse'
>;

const artifactTagHeaderTypeMap: Record<ArtifactTypeRequiresHeader, ReactNode> =
  {
    inside_dial: <ProductName product='dial' forceNewBranding />,
    clearinghouse: 'clearinghouse',
  };

function getArtifactTagHeaderType(
  claimDetails: ClaimDetails,
  artifact: ArtifactWithTag,
): ArtifactTypeRequiresHeader | null {
  if (claimDetails.best_response.type === 'inside_dial') return 'inside_dial';
  if (
    claimDetails.best_response.collector_type === 'clearinghouse' &&
    (artifact.tag === 'detail' || artifact.tag === 'listing')
  ) {
    return 'clearinghouse';
  }
  return null;
}

function getArtifactTagHeader(
  claimDetails: ClaimDetails,
  artifact: ArtifactWithTag,
): ReactNode {
  const headerType = getArtifactTagHeaderType(claimDetails, artifact);
  if (!headerType) return null;
  return artifactTagHeaderTypeMap[headerType];
}

type TaggedArtifact = { tag: string } & ClaimResponseEntryArtifact;

export default function DocumentsCard({
  claimDetails,
  entry,
}: {
  claimDetails: ClaimDetails;
  entry?: ClaimResponseEntry;
}) {
  const [selectedTab, setSelectedTab] = useState<string>(OVERVIEW_TAB);
  const { hasPermission } = useHasPermission();
  const { preview } = useContext(ProductPreviewContext);
  const [unmatchClaimDialogOpen, setUnmatchClaimDialogOpen] = useState(false);
  const flags = useFlags();

  const allowUnmatch =
    flags.unmatchResponse &&
    entry?.match &&
    entry.insurer_reference &&
    claimDetails.best_response.type === 'collector' &&
    claimDetails.best_response.credential?.collector !== undefined;

  const artifacts = useMemo(
    () =>
      entry?.artifacts
        ?.filter(
          (artifact): artifact is TaggedArtifact =>
            typeof artifact.tag === 'string',
        )
        .sort(({ tag: tagA }, { tag: tagB }) => {
          const tagAIndex = TAG_ORDER.indexOf(tagA?.toLowerCase());
          const tagBIndex = TAG_ORDER.indexOf(tagB?.toLowerCase());
          return tagBIndex - tagAIndex;
        }) ?? [],
    [entry?.artifacts],
  );

  // Preserve selected tag across entries but default to overview tab if
  // selected tag does not exist on the current entry
  const virtualSelectedTab = useMemo(() => {
    const artifactTabs = artifacts.map((artifact) => artifact.tag);
    return artifactTabs.includes(selectedTab) ? selectedTab : OVERVIEW_TAB;
  }, [artifacts, selectedTab]);

  return (
    <Card
      variant='outlined'
      color='accent-secondary'
      data-testid='documents-card'
    >
      <CardHeader
        title='Documents'
        action={
          <Stack direction='row'>
            {hasPermission('read:claim-file-download') && (
              <ActionMenu claimDetails={claimDetails} />
            )}
            {allowUnmatch && (
              <Box sx={{ ml: 'auto' }}>
                <Button
                  variant='darkBlueGradient'
                  onClick={() => setUnmatchClaimDialogOpen(true)}
                  startIcon={<LinkOff />}
                >
                  Delink EOB
                </Button>
              </Box>
            )}
          </Stack>
        }
        sx={{
          gap: 3,
          [`& .${cardHeaderClasses.content}`]: { flexGrow: 0 },
          [`& .${cardHeaderClasses.action}`]: { flexGrow: 1, mr: 0 },
        }}
      />
      <CardContent sx={{ px: 0 }}>
        <TabContext value={virtualSelectedTab}>
          <KeyboardShortcutBarrier>
            <RoundedTabList
              variant='standard'
              centered
              onChange={(e, tab) => setSelectedTab(tab)}
            >
              <Tab label='overview' value={OVERVIEW_TAB} />
              {artifacts.map((artifact) => {
                const tagHeader = getArtifactTagHeader(claimDetails, artifact);
                return (
                  <Tab
                    key={artifact.id}
                    label={
                      <Stack sx={{ fontWeight: variables.weightMedium }}>
                        {tagHeader && (
                          <Typography color='inherit' fontSize='0.625rem'>
                            {tagHeader}
                          </Typography>
                        )}
                        <span>{artifact.tag}</span>
                      </Stack>
                    }
                    value={artifact.tag}
                  />
                );
              })}
            </RoundedTabList>
          </KeyboardShortcutBarrier>
          <Divider sx={{ mb: 1 }} />
          <TabPanel value={OVERVIEW_TAB}>
            <ServiceTable entry={entry} procedures={claimDetails.procedures} />
          </TabPanel>
          {artifacts.map((artifact) => (
            <TabPanel key={artifact.id} value={artifact.tag}>
              <ExcludeFullStory>
                {!preview ? (
                  <Artifact artifact={artifact} />
                ) : (
                  <ArtifactPreview
                    artifact={artifact}
                    tagHeader={getArtifactTagHeader(claimDetails, artifact)}
                    facilityName={claimDetails.facility.name ?? undefined}
                  />
                )}
              </ExcludeFullStory>
            </TabPanel>
          ))}
        </TabContext>
      </CardContent>
      {/**
       * XXX: This needs to remain open after the claim details query
       * invalidation when posting an unmatch request until the user explicitly
       * closes it, hence no conditional rendering.
       */}
      <UnmatchClaimDialog
        open={unmatchClaimDialogOpen}
        onClose={() => setUnmatchClaimDialogOpen(false)}
        entry={entry}
        claimId={claimDetails.id}
      />
    </Card>
  );
}

function ArtifactPreview(props: {
  artifact: ArtifactWithTag;
  tagHeader?: ReactNode;
  facilityName?: string;
}) {
  const { artifact, tagHeader, facilityName } = props;
  const user = useUserProfile();
  const flags = useFlags();

  let displayHeader;
  if (tagHeader) {
    displayHeader = (
      <>{typeof tagHeader === 'string' ? formatTitle(tagHeader) : tagHeader} </>
    );
  }

  return (
    <Typography
      fontSize='1.125rem'
      fontWeight={variables.weightBold}
      color='primary.main'
      textAlign='center'
    >
      {displayHeader}
      {formatTitle(artifact.tag)} is not viewable at this time due to this
      facility being subscribed to Reporting Only, you may request to upgrade
      this facility&apos;s subscription to <ProductName product='assist' /> by{' '}
      <ExternalLink
        href={
          flags.autoPopulateUpgradeForm
            ? formatUpgradeRequestSupportUrl(user, facilityName)
            : PREVIEW_CONFIG.upgradeUrl
        }
      >
        clicking here
      </ExternalLink>
    </Typography>
  );
}

function ActionMenu({ claimDetails }: { claimDetails: ClaimDetails }) {
  const menuId = useId();
  const buttonId = useId();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const clientAPI = useContext(ClientAPIContext);
  invariant(clientAPI !== undefined);

  const open = Boolean(anchorEl);

  const downloadFile = async (url: string) => {
    // eslint-disable-next-line react/destructuring-assignment
    const response = await clientAPI.fetch(url);
    if (!response.ok) {
      enqueueSnackbar({ message: 'Failed to download file', variant: 'error' });
      return;
    }
    const filename = response.headers
      .get('Content-Disposition')
      ?.match(/filename=([^;]+)/)
      ?.at(1);
    invariant(typeof filename === 'string');
    saveAs(await response.blob(), filename);
  };

  const items = [
    { name: 'ati_file.json', url: `internal/download-ati/${claimDetails.id}` },
  ].concat(
    [
      'complete_job.zip',
      'complete_batch.zip',
      'collection.json',
      'batch.json',
    ].map((name) => ({
      name,
      url: `internal/download-job/${
        claimDetails.best_response.id
      }?${new URLSearchParams({ file: name })}`,
    })),
  );

  return (
    <>
      <ActionButton
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={isDownloading}
      >
        Select Action
      </ActionButton>
      <Menu
        id={menuId}
        MenuListProps={{ 'aria-labelledby': buttonId }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {items.map((item) => (
          <MenuItem
            key={item.name}
            onClick={() => {
              setAnchorEl(null);
              setIsDownloading(true);
              downloadFile(item.url).finally(() => setIsDownloading(false));
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function ActionButton(props: ButtonProps) {
  return (
    <Button
      variant='outlined'
      endIcon={<KeyboardArrowDownIcon />}
      sx={{
        background: 'white',
        border: variables.borderLight,
        color: 'text.primary',
        fontWeight: variables.weightRegular,
        [`& .${buttonClasses.endIcon}`]: {
          color: 'primary.main',
        },
        '&:hover': {
          backgroundColor: 'white',
          border: 'primary.main',
        },
      }}
      {...props}
    />
  );
}
