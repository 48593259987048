import { components, paths } from './generated/types';

declare global {
  /** Override the default readonly array to allow broader values to be searched with .includes.
   *
   * https://github.com/microsoft/TypeScript/issues/26255
   */
  interface ReadonlyArray<T> {
    includes<U>(
      searchElement: T & U extends never ? never : U,
      fromIndex?: number,
    ): boolean;
  }
}

type Address = NonNullable<ClaimDetails['facility']['address']>;
type CallStatus = NonNullable<components['schemas']['Call']['status']>;
type CheckNumber = { readonly identifier: string }; // XXX put into generated types
type ClaimCall = components['schemas']['Call'];
type ClaimCategory = components['schemas']['Claim']['categories'][0];
type ClaimDetails = components['schemas']['Claim'];
type ClaimFilterOptions = components['schemas']['ClaimFilterOptions'];
type ClaimHistoryEvent = components['schemas']['ClaimHistoryEvent'];
type ClaimInfo = components['schemas']['ClaimInfo'];
type ClaimList = components['schemas']['ClaimList'];
type ClaimListParams = Exclude<
  Exclude<paths['/claims/']['get']['parameters'], undefined>['query'],
  undefined
>;
type ClaimListTab = Exclude<ClaimListParams['claims_tab'], undefined>;
type ClaimNote = components['schemas']['ClaimNote'];
type ClaimNotePosted = components['schemas']['ClaimNotePosted'];
type ClaimPatchArgs = Writeable<
  paths['/claims/{claim_id}']['patch']['requestBody']['content']['application/json']
>;
type ClaimPaymentInfo = components['schemas']['ClaimPaymentInfo'];
type ClaimProcedure = components['schemas']['ClaimProcedure'];
type ClaimResponse = ClaimDetails['responses'][0];
type ClaimResponseEntry = ClaimDetails['best_response']['matches'][0];
type ClaimResponseEntryAdjudication =
  components['schemas']['ClaimResponseEntryAdjudication'];
type ClaimResponseEntryAdjudicationReason =
  components['schemas']['ClaimResponseEntryAdjudication']['reasons'][0];
type ClaimResponseEntryArtifact =
  components['schemas']['ClaimResponseEntryArtifact'];
type ClaimTabCount = components['schemas']['ClaimTabCount'];
type ClaimUIState = NonNullable<ClaimDetails['ui_state']>;
type ClaimUIStatePatchArgs =
  paths['/claims/{claim_id}/ui-state']['patch']['requestBody']['content']['application/json'];
type Collector = components['schemas']['Collector'];
type DenialReason = components['schemas']['DenialReason'];
type Facility = components['schemas']['Facility'];
type FacilityFilterOption = components['schemas']['FacilityFilterOption'];
type FacilityProduct = NonNullable<FacilityFilterOption['products']>[number];
type Individual = components['schemas']['Individual'];
type PatchClaimVariables = Writeable<
  paths['/claims/{claim_id}']['patch']['requestBody']['content']['application/json']
>;
type PatchClaimUIStateVariables =
  paths['/claims/{claim_id}/ui-state']['patch']['requestBody']['content']['application/json'];
type Patient = components['schemas']['Claim']['patient'];
type PostCallVariables =
  paths['/calls/']['post']['requestBody']['content']['application/json'];
type PostUnmatchVariables = components['schemas']['PostUnmatch'];
type PostUnmatchResponse = components['schemas']['PostUnmatchResponse'];
type Provider = components['schemas']['Provider'];
type Statistics = components['schemas']['Statistics'];
type StatusCodeKey = ClaimInfo['status_code'];
type StatusMapping = components['schemas']['StatusMapping'];
type StatusRecord = components['schemas']['StatusRecord'];
type Subscriber = components['schemas']['Claim']['subscriber'];
type User = components['schemas']['User'];
type WorkflowState = components['schemas']['WorkflowState'];
type WorkflowStateDiscriminator = Exclude<
  Exclude<
    paths['/client/workflow-states']['get']['parameters']['query'],
    undefined
  >['discriminator'],
  undefined
>;
type RedactedCredential = components['schemas']['Credential'];

export * from './claim-list';
export * from './generated/uiStatusEnum';
export type {
  Address,
  CallStatus,
  CheckNumber,
  ClaimCall,
  ClaimCategory,
  ClaimDetails,
  ClaimFilterOptions,
  ClaimHistoryEvent,
  ClaimInfo,
  ClaimList,
  ClaimListParams,
  ClaimListTab,
  ClaimNote,
  ClaimNotePosted,
  ClaimPatchArgs,
  ClaimPaymentInfo,
  ClaimProcedure,
  ClaimResponse,
  ClaimResponseEntry,
  ClaimResponseEntryAdjudication,
  ClaimResponseEntryAdjudicationReason,
  ClaimResponseEntryArtifact,
  ClaimTabCount,
  ClaimUIState,
  ClaimUIStatePatchArgs,
  Collector,
  DenialReason,
  Facility,
  FacilityFilterOption,
  FacilityProduct,
  Individual,
  PatchClaimUIStateVariables,
  PatchClaimVariables,
  Patient,
  PostCallVariables,
  PostUnmatchVariables,
  PostUnmatchResponse,
  Provider,
  RedactedCredential,
  Statistics,
  StatusCodeKey,
  StatusMapping,
  StatusRecord,
  Subscriber,
  User,
  WorkflowState,
  WorkflowStateDiscriminator,
};

export type Writeable<T> = { -readonly [P in keyof T]: Writeable<T[P]> };

export const DUMMY_FLAG = Symbol('DUMMY_FLAG');
declare module '@insidedesk/tuxedo' {
  export interface FlagSet {
    autoPopulateUpgradeForm?: boolean;
    claimListCallStatusFilter?: boolean;
    claimListInsurerSort?: boolean;
    claimListLastWorkedSort?: boolean;
    claimListPostedNotClosedFilter?: boolean;
    claimListTaxIdFilter?: boolean;
    filteredViewPaidInFull?: boolean;
    reportingOnlyBanner?: boolean;
    reportingOnlyProductPreview?: boolean;
    superDialNotDenticalOnly?: boolean;
    superDialNotes?: boolean;
    superDialStatus?: boolean;
    tooltipPlacementTop?: boolean;
    uiStatusV2?: boolean;
    unmatchResponse?: boolean;
    [DUMMY_FLAG]: undefined;
  }
}
