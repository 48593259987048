import { ButtonProps } from '@mui/material';
import { Dayjs } from 'dayjs';
import { FacilityFilterOption } from '.';

export type CategoryFilterOption = { id: string; label: string };
export const CATEGORY_OPTIONS: CategoryFilterOption[] = [
  { id: 'all', label: 'All' },
  { id: 'dental', label: 'Commercial' },
  { id: 'medicaid', label: 'Medicaid' },
  { id: 'ortho', label: 'Ortho' },
];

export type ClaimsTabSpec = {
  id: string;
  label: string;
  countable?: true;
  color?: ButtonProps['color'];
} | null;

export const CLAIMS_TAB_OPTIONS = [
  'all',
  'claim_batched_not_sent',
  'denial',
  'eob',
  'escalated',
  'expired',
  'expiring_soon',
  'in_progress',
  'issues',
  'open',
  'others',
  'paid_in_full',
  'payor_portal_issues',
  'snoozed',
  'worked',
] as const;
export type ClaimsTabFilterOption = (typeof CLAIMS_TAB_OPTIONS)[number];

export type ClaimFilterOption = { id: string; label: string };
export type ClaimFilterValues = {
  arAge: ClaimFilterOption[];
  portal: ClaimFilterOption[];
  submittedAmount: ClaimFilterOption[];
  originalInsurerName: ClaimFilterOption[];
  callStatus: ClaimFilterOption[];
  workflow: ClaimFilterOption[];
  taxId: ClaimFilterOption[];
  responseStatus: ClaimFilterOption[];
  expectedAmount: ClaimFilterOption[];
};

export type ClaimListDrawerFilters = ClaimFilterValues & {
  dosStart: Dayjs | null;
  dosEnd: Dayjs | null;
  paidSubscriber: boolean;
  updated: boolean;
  postedNotClosed: boolean;
};

export type ClaimListFilterValues = {
  facility: FacilityFilterOption | null;
  category: CategoryFilterOption;
  claimsTab: ClaimsTabFilterOption;
  searchQuery?: string;
} & ClaimListDrawerFilters;

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}
export type SortAttribute =
  // XXX Typo, need to fix in client-api first
  | 'amout_expected'
  | 'ar_age'
  | 'insurer'
  | 'last_worked'
  | 'patient'
  | 'service_date'
  | 'submitted_date';
export type Sort = {
  attribute: SortAttribute;
  direction: SortDirection;
};
