import {
  enqueueSnackbar,
  InsideVaultLogo,
  ProductName,
  ProductPreviewContext,
  TwoToneHeader,
  useProductName,
} from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import { Download, ExitToApp, OpenInNew } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Radio,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { PropsWithChildren, useContext, useReducer, useState } from 'react';

import { VAULT_CONFIG } from '../../../config';
import { useClaimCredentials } from '../../../hooks';
import { ClaimDetails, RedactedCredential } from '../../../types';
import ModalCloseButton from '../../ModalCloseButton';

type ExternalLoginAuto = {
  type: 'EXTERNAL_LOGIN_AUTO';
  args: { credentialId: number };
};

const VAULT_WEB_STORE_URL = `https://chrome.google.com/webstore/detail/insidevault/${VAULT_CONFIG.vaultExtensionId}?hl=en`;
const MODAL_HEADER_PROPS = {
  fontWeight: variables.weightSemiBold,
  fontSize: 38,
};

const ModalContainedButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    display: ' flex',
    justifyContent: 'space-around',
    minWidth: theme.spacing(24),
    height: theme.spacing(5.5),
    textTransform: 'uppercase',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
}));

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'justifyCenter',
})<{ justifyCenter?: boolean }>(({ theme, justifyCenter }) => ({
  '& .MuiDialog-paper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: justifyCenter ? 'center' : 'flex-start',
    gap: '2rem',
    minWidth: theme.spacing(80),
    minHeight: theme.spacing(59.5),
    padding: '2rem',
  },

  '& .MuiDialogTitle-root': {
    display: 'block',
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    fontSize: '1rem',
    maxWidth: '90%',
    fontWeight: variables.weightSemiBold,
    color: theme.palette.text.primary,
  },

  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    ...(justifyCenter && { justifyContent: 'center' }),
    flexGrow: 1,
    fontSize: '1rem',
    fontWeight: variables.weightSemiBold,
    padding: `${theme.spacing(1)} !important`,
  },
}));

type ModalState = Record<
  | 'notChromeModal'
  | 'notInstalledModal'
  | 'noCredentialsModal'
  | 'multipleCredentialsModal',
  { open: boolean }
>;

type ModalAction =
  | { type: 'open'; payload: keyof ModalState }
  | { type: 'close'; payload: keyof ModalState };

function modalReducer(state: ModalState, action: ModalAction): ModalState {
  switch (action.type) {
    case 'open':
      return {
        ...(Object.fromEntries(
          Object.entries(state).map(([k]) => [k, { open: false }] as const),
        ) satisfies ModalState),
        [action.payload]: { open: true },
      };
    case 'close':
      return { ...state, [action.payload]: { open: false } };
    default:
      throw new Error('Invalid action type');
  }
}

export default function VaultAutoLogin(props: {
  claimId: ClaimDetails['id'];
  portal: string | null;
}) {
  const { claimId, portal } = props;
  const theme = useTheme();
  const [modalState, dispatch] = useReducer(modalReducer, {
    notChromeModal: { open: false },
    notInstalledModal: { open: false },
    noCredentialsModal: { open: false },
    multipleCredentialsModal: { open: false },
  });
  const productName = useProductName('vault', { forceNewBranding: true }).join(
    '',
  );

  const {
    data: credentials,
    isLoading,
    isError,
  } = useClaimCredentials(claimId);

  const isChrome = /chrome/i.test(navigator.userAgent);
  const insideVaultEnabled = document.getElementById('inside-vault') !== null;
  const { preview } = useContext(ProductPreviewContext);

  const handleClick = () => {
    if (isLoading || isError) return;
    if (!isChrome) {
      dispatch({ type: 'open', payload: 'notChromeModal' });
      return;
    }
    if (!insideVaultEnabled) {
      dispatch({ type: 'open', payload: 'notInstalledModal' });
      return;
    }

    switch (credentials?.length) {
      case 0:
        dispatch({ type: 'open', payload: 'noCredentialsModal' });
        return;
      case 1:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sendAutoLoginMessage({ credentialId: credentials[0]!.id }, productName);
        return;
      default:
        dispatch({ type: 'open', payload: 'multipleCredentialsModal' });
    }
  };

  return (
    <>
      {!isLoading && !isError && (
        <>
          <NotChromeModal
            open={modalState.notChromeModal.open}
            onClose={() =>
              dispatch({ type: 'close', payload: 'notChromeModal' })
            }
          />
          <VaultNotInstalledModal
            open={modalState.notInstalledModal.open}
            onClose={() =>
              dispatch({ type: 'close', payload: 'notInstalledModal' })
            }
          />
          {portal !== null && (
            <NoCredentialsModal
              open={modalState.noCredentialsModal.open}
              onClose={() =>
                dispatch({ type: 'close', payload: 'noCredentialsModal' })
              }
              portal={portal}
            />
          )}
          <MultipleCredentialsModal
            open={modalState.multipleCredentialsModal.open}
            onClose={() =>
              dispatch({ type: 'close', payload: 'multipleCredentialsModal' })
            }
            credentials={credentials}
          />
        </>
      )}
      <DisabledToolip show={portal === null && credentials?.length === 0}>
        <Button
          variant='darkBlueGradient'
          sx={{ ml: theme.spacing(-1), mb: theme.spacing(0.5) }}
          onClick={handleClick}
          disabled={
            isLoading ||
            isError ||
            (portal === null && credentials?.length === 0) ||
            preview
          }
          endIcon={<OpenInNew />}
        >
          Launch Portal
        </Button>
      </DisabledToolip>
    </>
  );
}

function NotChromeModal(props: { open: boolean; onClose: () => void }) {
  const { open, onClose } = props;
  return (
    <StyledDialog open={open} onClose={onClose} justifyCenter>
      <ModalCloseButton onClick={onClose} />
      <VaultLogo />
      <DialogTitle>
        You are using an unsupported browser. Currently,{' '}
        <ProductName product='vault' forceNewBranding /> only works with{' '}
        <a
          style={{ display: 'contents' }}
          href='https://www.google.com/chrome/'
          target='_blank'
          onClick={onClose}
          rel='noreferrer'
        >
          Google Chrome
        </a>
      </DialogTitle>
    </StyledDialog>
  );
}

function VaultNotInstalledModal(props: { open: boolean; onClose: () => void }) {
  const { open, onClose } = props;
  const handleClick = () => {
    window.open(VAULT_WEB_STORE_URL, '_blank')?.focus();
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose} justifyCenter>
      <ModalCloseButton onClick={onClose} />
      <VaultLogo />
      <DialogTitle>
        Do you want to install the{' '}
        <ProductName product='vault' forceNewBranding /> app to manage all your
        passwords?
      </DialogTitle>
      <ModalContainedButton
        variant='contained'
        startIcon={<Download />}
        onClick={handleClick}
      >
        Install now
      </ModalContainedButton>
      <Button onClick={onClose}>Cancel</Button>
    </StyledDialog>
  );
}

function NoCredentialsModal(props: {
  open: boolean;
  onClose: () => void;
  portal: string;
}) {
  const { open, onClose, portal } = props;

  const handleClick = () => {
    window.open(portal, '_blank')?.focus();
    onClose();
  };

  // XXX: Medicaid-nc (nc.gov) credentials are unique per user which is
  // unsupported so vault won't attempt to save them. This should be removed
  // when a generic solution is implemented as part of
  // https://inside-desk.atlassian.net/browse/ID-3785
  if (/.*nc\.gov.*/.test(portal)) {
    return (
      <StyledDialog open={open} onClose={onClose} justifyCenter>
        <DialogTitle>
          <ModalCloseButton onClick={onClose} />
          <TwoToneHeader
            text={['user specific ', 'credentials required ']}
            {...MODAL_HEADER_PROPS}
          />
        </DialogTitle>
        <DialogContent sx={{ gap: 2, textAlign: 'center' }}>
          <Box>
            Credentials for this portal are not sharable due to being user
            specific.
          </Box>
          <Box>
            You may select the Launch Portal button below to open the portal and
            manually enter your specific login credentials.
          </Box>
          <Box>
            InsideDesk will not save or share credentials for this portal.
          </Box>
        </DialogContent>
        <Button
          variant='darkBlueGradient'
          onClick={handleClick}
          endIcon={<OpenInNew />}
        >
          Launch Portal
        </Button>
      </StyledDialog>
    );
  }

  return (
    <StyledDialog open={open} onClose={onClose} justifyCenter>
      <DialogTitle>
        <ModalCloseButton onClick={onClose} />
        <TwoToneHeader text={['no ', 'credentials ']} {...MODAL_HEADER_PROPS} />
      </DialogTitle>
      <DialogContent sx={{ gap: 2, textAlign: 'center' }}>
        <Box>No credentials were found for this claim.</Box>
        <Box>
          You may select the Launch Portal button below to open the portal and
          manually enter your login credentials.
        </Box>
        <Box>
          If the login is successful and you have Payor Portal Management
          permissions, a pop up will appear to prompt you to save those
          credentials for future automated portal login.
        </Box>
      </DialogContent>
      <Button
        variant='darkBlueGradient'
        onClick={handleClick}
        endIcon={<OpenInNew />}
      >
        Launch Portal
      </Button>
    </StyledDialog>
  );
}

function MultipleCredentialsModal(props: {
  open: boolean;
  onClose: () => void;
  credentials: RedactedCredential[];
}) {
  const { open, onClose, credentials } = props;
  const [selectedCredentialId, setSelectedCredentialId] = useState(
    credentials[0]?.id ?? -1,
  );
  const theme = useTheme();
  const productName = useProductName('vault', { forceNewBranding: true }).join(
    '',
  );
  const handleClick = () => {
    sendAutoLoginMessage({ credentialId: selectedCredentialId }, productName);
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>
        <ModalCloseButton onClick={onClose} />
        <TwoToneHeader
          text={['multiple ', 'credentials ']}
          {...MODAL_HEADER_PROPS}
        />
      </DialogTitle>
      <DialogContent sx={{ gap: 2 }}>
        Multiple credentials found. Which would you like to use?
        {credentials.map((credential) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: theme.spacing(8.5),
              border: `1px solid ${theme.palette.grey[100]}`,
              borderRadius: theme.spacing(2.5),
              cursor: 'pointer',
            }}
            key={credential.id}
            onClick={() => setSelectedCredentialId(credential.id)}
          >
            <Radio checked={selectedCredentialId === credential.id} />
            <Typography variant='h6' fontWeight={variables.weightMedium}>
              Username: {credential.username}
            </Typography>
          </Box>
        ))}
      </DialogContent>
      <ModalContainedButton
        variant='contained'
        startIcon={<ExitToApp />}
        onClick={handleClick}
        disabled={selectedCredentialId === -1}
      >
        Login Now
      </ModalContainedButton>
    </StyledDialog>
  );
}

function DisabledToolip({
  show,
  children,
}: PropsWithChildren<{ show: boolean }>) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!show) return <>{children}</>;

  return (
    <Tooltip
      title="Sorry, we couldn't find any credentials or insurance portal associated with this claim."
      arrow
      data-testid='disabled-tooltip'
    >
      {/* To enable tooltip functionality with a disabled button */}
      <span style={{ pointerEvents: 'auto' }}>{children}</span>
    </Tooltip>
  );
}

function VaultLogo() {
  const productName = useProductName('vault', {
    forceNewBranding: true,
  }).join('');
  return <Box component='img' src={InsideVaultLogo} alt={productName} />;
}

function sendAutoLoginMessage(
  args: ExternalLoginAuto['args'],
  productName: string,
) {
  try {
    chrome.runtime.sendMessage<ExternalLoginAuto>(
      VAULT_CONFIG.vaultExtensionId,
      { type: 'EXTERNAL_LOGIN_AUTO', args },
    );
  } catch (err) {
    enqueueSnackbar({
      variant: 'error',
      message: `Failed to send message to ${productName}.`,
    });
  }
}
