import {
  BlurPHIContext,
  CenterInPage,
  LoadingSpinner,
  PHI,
  SearchableDropdown,
  TwoToneHeader,
} from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Card, Stack, TablePagination } from '@mui/material';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useAtom, useAtomValue } from 'jotai';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  ClaimList,
  FacilityLastUpdated,
  FilterDrawer,
  LeftSidebar,
  QuickFilters,
  ReportingOnlyBanner,
  SoftPopup,
  Sorting,
} from '../../components';
import { TAB_LABELS } from '../../constants';
import { useHydrateClaimListOptions, useStatusMapping } from '../../hooks';
import {
  claimListFiltersAtom,
  claimListOptionsAtom,
  facilityPendingAtom,
  pageAtom,
  requiresFiltersAtom,
  rowsPerPageAtom,
  totalItemsAtom,
} from '../../state';
import type { FacilityFilterOption } from '../../types';
import { facilityInPreview } from '../../utils';
import './ClaimListRoute.scss';

/**
 * The drawer should auto-open whenever drawer filters are required
 * XXX Bit of a hack to avoid putting drawer state in jotai
 */
function useAutoOpenDrawer(onOpen: () => void) {
  const savedCallback = useRef(onOpen);
  useEffect(() => {
    savedCallback.current = onOpen;
  }, [onOpen]);

  const requiresDrawerFilters = useAtomValue(requiresFiltersAtom);
  useEffect(() => {
    if (requiresDrawerFilters) savedCallback.current();
  }, [requiresDrawerFilters]);
}

export default function ClaimListRoute() {
  const { blur } = useContext(BlurPHIContext);

  const loadingFacilityOptions = useAtomValue(facilityPendingAtom);
  const [filters, updateFilters] = useAtom(claimListFiltersAtom);
  const options = useAtomValue(claimListOptionsAtom);
  const [page, setPage] = useAtom(pageAtom);
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const totalItems = useAtomValue(totalItemsAtom);

  const [drawerOpen, setDrawerOpen] = useState(false);
  useAutoOpenDrawer(() => setDrawerOpen(true));

  const requiresDrawerFilters = useAtomValue(requiresFiltersAtom);

  // XXX would ideally be handled by jotai
  useHydrateClaimListOptions();

  const statusMappingQuery = useStatusMapping();

  if (statusMappingQuery.isError) {
    throw new Error('Error loading data');
  }

  if (
    statusMappingQuery.isLoading ||
    statusMappingQuery.data === undefined ||
    loadingFacilityOptions ||
    filters.facility === null
  ) {
    return (
      <CenterInPage>
        <LoadingSpinner />
      </CenterInPage>
    );
  }

  return (
    <Box id='claim-list-route' className='flex-scroll-parent'>
      <Stack direction='row' alignItems='center' sx={{ mb: 1, px: 3 }}>
        <TwoToneHeader
          variant='h3'
          fontWeight={variables.weightLight}
          icon={<DescriptionOutlinedIcon />}
          text={[
            `Assist / ${TAB_LABELS[filters.claimsTab]} - `,
            <PHI key='name'>{filters.facility.name}</PHI>,
          ]}
          sx={{ ml: 3, pr: 2 }}
        />
        <SearchableDropdown<FacilityFilterOption>
          buttonLabel='Change Office'
          buttonProps={{ size: 'small' }}
          blurInputValue={blur}
          inputLabel='Search Office'
          disableClearable
          getOptionLabel={({ id, name }) => name ?? id.toString()}
          renderOption={(props, option) => (
            <li {...props}>
              <PHI>{option.name ?? option.id.toString()}</PHI>
            </li>
          )}
          groupBy={({ name }) => name?.charAt(0) ?? ' '}
          options={options.facility}
          value={filters.facility}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              updateFilters({ facility: newValue });
            }
          }}
        />
        <Box sx={{ ml: 'auto' }}>
          <FacilityLastUpdated facilityID={filters.facility.id} />
        </Box>
      </Stack>
      <Stack
        direction='row'
        spacing={2}
        sx={{ overflowY: 'auto', flexGrow: 1 }}
      >
        <LeftSidebar />
        <Card
          variant='flat'
          sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, flex: 1 }}
        >
          <Stack
            className='flex-scroll-parent'
            sx={{ minWidth: 'fit-content', gap: 1, pt: 2, px: 2 }}
          >
            <QuickFilters onOpenDrawer={() => setDrawerOpen(true)} />
            <Sorting />
            <Box className='flex-scrollable' sx={{ minWidth: 'fit-content' }}>
              {!requiresDrawerFilters ? (
                <ClaimList statusMapping={statusMappingQuery.data} />
              ) : (
                <DrawerFiltersRequired />
              )}
            </Box>
            <TablePagination
              component='div'
              count={totalItems ?? 0}
              onPageChange={(e, page_) => setPage(page_)}
              onRowsPerPageChange={(e) =>
                setRowsPerPage(parseInt(e.target.value, 10))
              }
              page={page}
              rowsPerPage={rowsPerPage}
              sx={{ marginRight: 2, marginLeft: 'auto' }}
            />
          </Stack>
        </Card>
      </Stack>
      <FilterDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <ReportingOnlyBanner
        inPreview={facilityInPreview(filters.facility)}
        facilityName={filters.facility.name ?? undefined}
      />
    </Box>
  );
}

function DrawerFiltersRequired() {
  return (
    <Box
      display='flex'
      height='100%'
      alignItems='center'
      justifyContent='center'
    >
      <SoftPopup
        Icon={FilterAltIcon}
        message='Please select at least one filter from the right side, or both a start and end date if only filtering by date of service.'
      />
    </Box>
  );
}
