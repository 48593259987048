/** Utils related to claim data */
import dayjs from 'dayjs';
import type { ClaimResponse, ClaimUIState } from '../types';

export function getArAgeColor(arAge: number) {
  if (arAge < 30) return 'success.main';
  if (arAge < 90) return 'warning.main';
  if (arAge < 365) return 'warning.dark';
  return 'error.main';
}

export function getArAgeText(arAge: number) {
  if (arAge < 30) return '<30';
  if (arAge < 45) return '30-45';
  if (arAge < 60) return '45-60';
  if (arAge < 90) return '60-90';
  if (arAge < 120) return '90-120';
  if (arAge < 305) return '120-305';
  if (arAge < 365) return '305-365';
  return '>365';
}

export function isSnoozed(uiState: ClaimUIState): boolean {
  if (uiState.snooze_until === null) {
    return false;
  }

  const today = dayjs();
  return !(
    dayjs(uiState.snooze_until).isSame(today, 'date') ||
    dayjs(uiState.snooze_until).isBefore(today, 'date')
  );
}

export function isClearinghouseResponse(response: ClaimResponse) {
  return ['DXC_API', 'clearinghouse'].includes(response.collector_type ?? '');
}

export function isInsideDialResponse(response: ClaimResponse) {
  return response.type === 'inside_dial';
}
