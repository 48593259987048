import { ProductName } from '@insidedesk/tuxedo';
import React, { ReactNode } from 'react';
import {
  FailedCallIcon,
  NewCallIcon,
  OngoingCallIcon,
  SuccessfulCallIcon,
} from '../assets';
import { CallStatus } from '../types';

export function getCallGradient(status: CallStatus | null): [string, string] {
  const statusMapping: Record<CallStatus, [string, string]> = {
    FAILURE: ['#EC605B', '#E62B24'],
    PENDING: ['#FF7F00', '#D56B03'],
    SUCCESS: ['#46AE82', '#349286'],
  };
  return mapStatus(status, statusMapping, ['#FFFFFF', '#DAE6EB']);
}

export function getCallIcon(status: CallStatus | null) {
  const statusMapping: Record<
    CallStatus,
    React.FC<React.SVGProps<SVGSVGElement>>
  > = {
    FAILURE: FailedCallIcon,
    PENDING: OngoingCallIcon,
    SUCCESS: SuccessfulCallIcon,
  };
  return mapStatus(status, statusMapping, NewCallIcon);
}

export function getCallStatusText(status: CallStatus | null) {
  const statusMapping: Record<CallStatus, string> = {
    FAILURE: 'Failed',
    PENDING: 'In Progress',
    SUCCESS: 'Succeeded',
  };
  return mapStatus(status, statusMapping, 'Unknown');
}

export function getCallButtonText(status: CallStatus | null) {
  const statusMapping: Record<CallStatus, ReactNode> = {
    FAILURE: 'Call Failed - Please Try Again',
    PENDING: 'Call In Progress...',
    SUCCESS: 'Call Complete',
  };
  return mapStatus(
    status,
    statusMapping,
    <>
      Start <ProductName product='dial' />
    </>,
  );
}

export function getCallTooltipText(status: CallStatus | null) {
  const statusMapping: Record<CallStatus, ReactNode> = {
    FAILURE: 'Call Failed, Please Try Again',
    PENDING: (
      <>
        <ProductName product='dial' /> Call In Progress
      </>
    ),
    SUCCESS: 'Call Complete',
  };
  return mapStatus(
    status,
    statusMapping,
    <>
      Start <ProductName product='dial' />
    </>,
  );
}

function mapStatus<T>(
  status: CallStatus | null,
  statusMapping: Record<CallStatus, T>,
  default_: T,
) {
  if (status === null) return default_;
  return statusMapping[status] ?? default_;
}
