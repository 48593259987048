import { defaultTheme } from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import {
  buttonClasses,
  createTheme,
  PaletteColor,
  PaletteColorOptions,
} from '@mui/material';
import _ from 'lodash';

declare module '@mui/material/styles' {
  interface Palette {
    dependent: PaletteColor;
    paidSubscriber: PaletteColor;
    postedNotClosed: PaletteColor;
  }
  interface PaletteOptions {
    dependent?: PaletteColorOptions;
    paidSubscriber?: PaletteColorOptions;
    postedNotClosed?: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    darkBlueGradient: true;
  }
}

/**
 * SCSS is mocked out in jest tests (probably something like
 * https://jestjs.io/docs/webpack#mocking-css-modules). So if we must rely on
 * having valid colors in the tests, we need to substitute variables accesses
 * with a valid color if it is being mocked.
 */
function color(key: keyof typeof variables, default_ = '#000') {
  const paletteColor = variables[key];
  if (paletteColor === undefined) return default_;
  if (paletteColor === key) return default_;
  return paletteColor;
}

const defaultTheme_ = createTheme(defaultTheme);
const assistTheme = {
  palette: {
    dependent: { main: color('secondaryMain') },
    paidSubscriber: { main: color('errorDark') },
    postedNotClosed: { main: color('textPrimary') },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'darkBlueGradient' },
          defaultProps: { size: 'large' },
          style: {
            [`&.${buttonClasses.root}`]: {
              height: defaultTheme_.spacing(4.5),
              border: `1px solid ${defaultTheme_.palette.primary.main}`,
              borderRadius: variables.borderRadius,
              fontSize: '0.8125rem',
              color: 'white',
              fontWeight: variables.weightMedium,
              textTransform: 'uppercase',
              background: `linear-gradient(180deg, ${defaultTheme_.palette.primary.main} 0%, #164152 100%)`,
            },
            [`&.${buttonClasses.disabled}`]: {
              color: `${defaultTheme_.palette.grey[500]}`,
              background: `${defaultTheme_.palette.grey[300]}`,
              border: 'none',
            },
          },
        },
      ],
    },
  },
};

const theme = createTheme(_.merge(defaultTheme_, assistTheme));
export default theme;
